const FetchUtil = {
  fetchSVG(url, callback){
    if(typeof XMLHttpRequest === "undefined")
      return;
    const xobj = new XMLHttpRequest();
    xobj.overrideMimeType("image/svg+xml");
    xobj.open('GET', url, true); // Replace 'my_data' with the path to your file
    xobj.onreadystatechange = function () {
      if (xobj.readyState === 4 && xobj.status === 200) {
        // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
        const responseText = xobj.responseText;
        callback(responseText);
      }
    };
    xobj.send(null);
  },

  fetchJson(url, callback){
    if(typeof XMLHttpRequest === "undefined")
      return;
    const xobj = new XMLHttpRequest();
    xobj.open('GET', url, true); // Replace 'my_data' with the path to your file
    xobj.onreadystatechange = function () {
      if (xobj.readyState === 4 && xobj.status === 200) {
        // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
        const responseText = xobj.responseText;
        callback(JSON.parse(responseText));
      }
    };
    xobj.send(null);
  }
};

export default FetchUtil;
