import React, { Component } from "react"

import style from "./DivButton.module.scss";
import PropTypes from "prop-types"

class DivButton extends Component {
  render() {
    return (
      <div className={style.parent + " " + this.props.className}
              role={'button'} tabIndex="0"
              onClick={this.props.onClick} onKeyUp={this.props.onClick}>
        {this.props.children}
      </div>
    )
  }
}

DivButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default DivButton;
