import React from "react";
import style from "./Boxes.module.scss";
import PropTypes from "prop-types";

const Box = function({ children, className}){
    const cn = style.canvas + ((className) ? " " + className : "");
    let customStyle = {};

    return (
      <div className={cn} style={customStyle}>
          {children}
      </div>
    )
};

Box.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Box;
